/*.ServiceView label{
  white-space: nowrap;
  overflow: hidden;
}*/

.microsite-iframe{
  display: block;
  width: 100%;
  height: 73vh;
  border: none;
}

.header-logo{
  height: 64px;
  width: 64px;
}
.wizard-navi-button{
  width:150px
}

.scrollable-div{

    max-height: 250px;
    overflow-y: scroll;
}

.form-field-long{
  min-width: 412px;
}

.form-field-small{
  min-width: 120px;
}

.form-field-short{
  min-width: 200px;
}

.create-order-screen{
  min-width: 1000px;
  margin-left: 8px;
  margin-right: 8px;
  
}
.user-panel{
  margin-top:8px;
  margin-right:8px
}

.hundred-percent-heigth{
  height: 100%;
  padding: 0 0 0 0 !important;
}

.service-edit-modal .ant-modal-body{
  width: 100% !important;
  padding: 1px 1px 1px 1px;
}

.custom-select-form-container {
  margin-left: 11px;
  margin-right: 11px;
}

.custom-select-option-container {
  padding: 0 0 0 0 !important;
}

.custom-select-option-container .custom-select-option {
  padding-left: 11px;
}
.custom-select-option{
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: left;
}

.ant-select{
  width:100%
}

.compact-divider{
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.ServiceEdit .ant-divider{
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.ServiceEdit .ant-picker{
  width: 100%;
}

.ant-form-item-control{
  padding-left: 4px;
}
 .ant-form-item-label{
  padding:0;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}
 .ant-form-item{
  text-align: left;
  margin-bottom: 4px !important;
  white-space: nowrap;
  overflow: hidden;
}

.ant-form-item-explain-error{
  margin-bottom: 4px;
}
.ServiceEdit .ant-cascader .ant-select-selection-item{
  font-weight: bold;
  text-align: left;
}
.ServiceEdit .ant-cascader{
  width: 100%;
  padding: 0 0 0 0;
}

.ServiceEdit{
  margin: 8px;
}
/*.PanelHeader {
    border: 1px solid;
}

.PanelBody {
  border: 1px solid;
}*/

.Container {
    border: 1px solid;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.form-center {
  display:flex;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
